<template>
    <div class="custom_area">
        <section class="list_box">
            <div class="tit_box">
                <small-title tit="自定义大区模板管理"></small-title>
                <el-button type="primary" @click="addOpen">新建大区模板</el-button>
            </div>
            <div class="table_box">
                <el-table :data="state.tableData" stripe border style="width: 100%" height="551"
                    :header-cell-style="{ background: '#f5f5f5', color: '#80899b', fontWeight: 400 }">
                    <el-table-column label="序号" width="50" align="center">
                        <template #default="scope">
                            {{ (state.query.pageNum - 1) * state.query.pageSize + scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="大区模板名称" />
                    <el-table-column prop="region" label="大区名称" />
                    <el-table-column label="操作" width="120px" align="center">
                        <template #default="scope">
                            <el-button type="text" size="small" @click="goToDetails(scope.row)">详情</el-button>
                            <el-button type="text" size="small" @click="deleteHandel(scope.row)"
                                style="color: #F56C6C;">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="no_data" v-if="state.tableData.length === 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
            </div>
            <div class="page_box">
                <el-pagination layout="prev, pager, next" background :page-size="state.query.pageSize"
                    v-model:current-page="state.query.pageNum" :total="state.total"></el-pagination>
            </div>
        </section>
    </div>
    <!-- 新建大区模板弹框 -->
    <add-dialog :show="addState.show" @close="addClose" @submit="addSubmit"></add-dialog>
</template>

<script setup>
import { onBeforeMount, reactive, watch } from 'vue';
import SmallTitle from '@/components/common/SmallTitle.vue';
import useDelete from './useDelete';
import { useRouter } from 'vue-router';
import { templateGetList } from '@/request/dishApi';
import AddDialog from './AddDialog.vue';
import useAdd from './useAdd';

const $router = useRouter()
const state = reactive({
    query: { pageNum: 1, pageSize: 10 },
    tableData: [],
    total: 0
})

// 获取列表
async function getList() {
    const { data } = await templateGetList(state.query);
    state.tableData = data?.list || [];
    state.total = data?.total || 0;
}

// 分页
watch(() => state.query.pageNum, () => {
    getList()
})

// 新增
const { state: addState, open: addOpen, close: addClose, submit: addSubmit } = useAdd()

// 删除
const { deleteHandel } = useDelete(getList)

// 跳转详情
function goToDetails(row) {
    $router.push({ path: '/dishsearch/areatempdetails', query: { id: row.id } })
}

onBeforeMount(() => {
    getList()
})
</script>

<style scoped lang="scss">
.custom_area {
    padding: 20px 40px;

    .list_box {
        background-color: #fff;
        border-radius: 14px;
        padding: 20px;

        .tit_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .search_box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .int {
                width: 250px;
            }
        }

        .table_box {
            padding-top: 20px;
            position: relative;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 180px;
            }
        }

        .page_box {
            text-align: right;
            padding: 20px 0;
        }
    }
}
</style>