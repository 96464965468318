import { reactive } from "vue";
import { templateSave } from '@/request/dishApi';
import { ElLoading } from 'element-plus';
import { useRouter } from 'vue-router'

// 新增大区模板弹框
export default () => {
    const $router = useRouter()

    const state = reactive({
        show: false
    })

    function open() {
        state.show = true;
    }

    function close() {
        state.show = false;
    }

    async function submit(form) {
        const loadingInstance = ElLoading.service()
        const { data } = await templateSave(form)
        $router.push({ path: '/dishsearch/areatempdetails', query: { id: data } })
        close()
        loadingInstance.close()
    }

    return { state, open, close, submit }
}