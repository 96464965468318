<template>
    <el-dialog :model-value="show" title="新建大区模板" width="500px" @close="close">
        <el-form ref="formRef" :model="state.form" :rules="state.rules">
            <el-form-item prop="name" label="名称">
                <el-input v-model.trim="state.form.name" placeholder="请输入" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="submitForm(formRef)">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue';

defineProps({
    show: { type: Boolean, required: true }
})

const emits = defineEmits(['close', 'submit'])

const state = reactive({
    form: { name: '' },
    rules: { name: [{ required: true, message: '请输入名称', trigger: 'blur' }] }
})

const formRef = ref(null)

// 关闭
function close() {
    formRef.value.resetFields()
    emits('close')
}

// 提交
function submitForm(formEl) {
    if (!formEl) return;
    formEl.validate((valid) => {
        if (valid) {
            emits('submit', state.form)
        }
    })
}
</script>

<style scoped lang="scss">
.img_list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: space-between;
    column-gap: 15px;
    row-gap: 15px;

    .li {
        width: 100%;
        aspect-ratio: 1/1;
        border: 5px solid #fff;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        .el-image {
            width: 100%;
            height: 100%;
        }

        &.acitve {
            border-color: #67C23A;
            box-shadow: 0 0 10px #ccc;
            position: relative;

            .icon {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 2;
                font-size: 40px;
                color: #67C23A;
            }
        }
    }
}
</style>